import ArchBrand from "@atoms/ArchBrand";
import DividerCenter from "@atoms/DividerCenter";
import EightBrand from "@atoms/EightBrand";
import ArrowDown from "@assets/svg/full_arrow_down.svg";
import ProjectManagementInstitute from "@atoms/ProjectManagementInstitute";
import ScaledAgileBrand from "@atoms/ScaledAgileBrand";
import React, { useState } from "react";
import styles from "./methodology.module.scss";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { motion } from "framer-motion";

const hide = {
  opacity: 0,
  y: 20,
};

const show = {
  opacity: 1,
  y: 0,
};

const transition = {
  type: "spring",
  stiffness: 20,
};

const Methodology = () => {
  const { t } = useTranslation();
  const [methodRef, isMethodVisible] = useOnScreen({ threshold: 0.1 });
  const breakpoints = useBreakpoint();
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = React.useCallback(() => {
    setIsHovered(prev => !prev);
  }, []);

  const handleEqualSign = () => {
    if (breakpoints.sm) {
      return (
        <div>
          <img src={ArrowDown} alt='arrow right' loading="lazy"  />
        </div>
      );
    } else {
      return <span>=</span>;
    }
  };
  return (
    <section
      id='methodology'
      ref={methodRef}
      className={`${styles.section} text-center`}
    >
      <motion.h2
        initial={hide}
        animate={isMethodVisible ? show : hide}
        transition={transition}
      >
        {t("SERVICES.METHODOLOGY")}
      </motion.h2>
      <motion.p
        initial={hide}
        animate={isMethodVisible ? show : hide}
        transition={{ ...transition, delay: 0.3 }}
        className={styles.description}
      >
        {t("SERVICES.METHODOLOGYSUB")}
      </motion.p>

      <div className={styles.brands}>
        <div className={styles.archScaled}>
          <div className='m-20'>
            <ArchBrand isHovered={isHovered} />
          </div>
          <span>+</span>
          <div className='m-20'>
            <ScaledAgileBrand isHovered={isHovered} />
          </div>
        </div>
        <span>+</span>
        <div className='m-20'>
          <ProjectManagementInstitute isHovered={isHovered} />{" "}
        </div>
        {handleEqualSign()}
        <div className='m-20'>
          <EightBrand handleHover={handleHover} />
        </div>
      </div>
      <DividerCenter mt={breakpoints.sm ? 164 : 250} />
    </section>
  );
};

export default Methodology;
